import axios from "axios";
const API_URL = process.env.REACT_APP_SERVER_URL || 'https://welcomer.app';

const CONFIG = {
  withCredentials: true,
};

export const getAuthStatus = async () => {
  return await axios.get(API_URL + "/api/auth/status", CONFIG);
};

export const redirectToAuth = () => {
  window.location.href = API_URL + "/api/auth/discord";
  return null;
};

export const Logout = async () => {
  var res = await axios.get(API_URL + "/api/auth/logout", CONFIG);

  if (res.status === 200) {
    window.location.href = "/";
  }
  return null;
};

export const validateUser = async (code) => {
  const res = await axios.get(API_URL + `/api/auth/discord/callback?code=${code}`, CONFIG);

  return res;
};

export const getGuilds = async () => {
  const res = await axios.get(API_URL + "/api/dashboard/guilds", CONFIG);

  return res;
};

export const getGuildBot = async (id) => {
  const res = await axios.get(API_URL + `/api/dashboard/guilds/@me/${id}`, CONFIG);

  return res;
};

export const submitWelcomerForm = async (data, guildId) => {
  const res = await axios.post(API_URL + 
    `/api/guilds/${guildId}`,
    data,
    CONFIG
  );
  return res;
};
