import styles from './ComingSoon.module.css'

export default function ComingSoon() {
  return (
      <div className={styles.centered}>
          <img className={styles.img} src="/img/coming_soon.svg" alt="coming soon illustration" />
      <h1>Coming Soon ...</h1>    
      </div>
  )
}
