import canvasTxt from "canvas-txt";

export default function drawCanvas(ref, formData, image, pfp, user, guild) {
  const canvas = ref.current;
  const ctx = canvas.getContext("2d");

  // ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
  canvas.width = canvas.width;

  ctx.font = "bold 60px Arial";
  ctx.globalAlpha = 1;

  ctx.fillStyle = formData?.color;
  ctx.textAlign = "center";
  canvasTxt.fontSize = formData?.fontSize;
  canvasTxt.fontFamily = "Arial";
  canvasTxt.fontWeight = "bold";
  ctx.drawImage(image, 0, 0, canvas.width, canvas.height);

  // ctx.fillText(
  //   `${formData?.welcomer.welcomerMessage}!`,
  //   canvas.width / 12,
  //   canvas.height / 2
  // );
  var message = formData?.message
    .replace("{user}", user.username + "#0001")
    .replace("{userid}", user.id)
    .replace("{username}", user.username)
    .replace("{guild}", guild.name)
    .replace("{guildid}", guild.id)
    .replace("{membercount}", "522");

  canvasTxt.drawText(
    ctx,
    message,
    canvas.width - 924,
    canvas.height - 250,
    canvas.width - 200,
    canvas.height - 200
  );

  var x = canvas.width / 2 + 128 / 4 - pfp.width / 2;
  var y = 25;

  ctx.beginPath();
  ctx.lineWidth = 5;
  ctx.strokeStyle = "black";
  ctx.arc(x + 100, y + 100, 100, 0, Math.PI * 2, true);
  ctx.stroke();
  ctx.closePath();
  ctx.clip();

  ctx.drawImage(pfp, x, y, 200, 200);

  return ctx;
}
