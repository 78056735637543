import React from "react";
import { useEffect } from "react";
import Redirect from "../components/Redirect/Redirect";

export default function StatusPage() {

  return (
    <div className="container">
      <Redirect link={"https://status.watchbot.app/bot/1008260316192329749"} pageName={"Status Page"} />
    </div>
  );
}
