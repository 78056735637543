import React, { useEffect } from "react";
import Redirect from "../components/Redirect/Redirect";

export default function SupportPage() {

  return (
    <div className="container">
      <Redirect link={"https://discord.gg/7TGc5ZZ7aM"} pageName={"Support Page"} />
    </div>
  );
}
