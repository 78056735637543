import React, { useState, useEffect, useContext } from "react";
import { UserContext } from "../../utils/context/userContext";

import { NavLink } from "react-router-dom";
import "./NavBar.css";
import { Logout } from "./../../utils/api";

export default function NavBar() {
  const [click, setClick] = useState(false);
  const [contextClick, setContextClick] = useState(false);
  const { user } = useContext(UserContext);

  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => {
    setClick(false);
  };

  const closeContextMenu = () => {
    setContextClick(!contextClick);
  };

  return (
    <>
      <nav className="navbar">
        <ul className="nav-container">
          <div className="left">

          <li className="logo">
            <NavLink to="/">
              <img
                className="logo-img"

                src={"/logo512.png"}
                alt="logo"
                />
            </NavLink>
          </li>
          <li className="nav-burger">
            <div className="nav-burger-container" onClick={handleClick}>
              <div className={click ? "nav-line-active" : "nav-line"} />
              <div className={click ? "nav-line-active" : "nav-line"} />
              <div className={click ? "nav-line-active" : "nav-line"} />
            </div>
          </li>
          <div className={click ? "menu menu-active" : "menu"}>
            <li className={click ? "nav-item-burger" : "nav-item"}>
              <NavLink id="home" to="/" onClick={closeMobileMenu}>
                Home
              </NavLink>
            </li>
            <li className={click ? "nav-item-burger" : "nav-item"}>
              <NavLink id="invite" to={"/invite"} onClick={closeMobileMenu}>
                Invite
              </NavLink>
            </li>
            <li className={click ? "nav-item-burger" : "nav-item"}>
              <NavLink id="support" to={"/support"} onClick={closeMobileMenu}>
                Support
              </NavLink>
            </li>
            <li className={click ? "nav-item-burger" : "nav-item"}>
              <NavLink id="dashboard" to="/dashboard" onClick={closeMobileMenu}>
                Dashboard
              </NavLink>
            </li>
            <li className={click ? "nav-item-burger" : "nav-item"}>
              <NavLink id="status" to={"/status"} onClick={closeMobileMenu}>
                Status
              </NavLink>
            </li>
          </div>
          </div>
          {user.loggedIn ? (
            <li className="right">
              <div className="avatar">
                {user.avatar ? (
                  <img
                    onClick={closeContextMenu}
                    className="avatar-img"
                    src={
                      "https://cdn.discordapp.com/avatars/" +
                      user.id +
                      "/" +
                      user.avatar +
                      "?size=256"
                    }
                    alt="avatar"
                  />
                ) : (
                  <div className="avatar-img nav-icon-placeholder">
                    {user.username[0]}
                  </div>
                )}

                <p onClick={closeContextMenu}>{user.username}</p>
                <div
                  className={contextClick ? "arrow arrow-active" : "arrow"}
                  onClick={closeContextMenu}
                 />
                <div
                  className={
                    contextClick ? "usermenu usermenu-active" : "usermenu"
                  }
                >
                  <ul>
                    <li>
                      <NavLink className="a-button" to={"/dashboard"}>
                        My Guilds
                      </NavLink>
                    </li>
                    <li className="logout" onClick={Logout}>
                      Logout
                    </li>
                  </ul>
                </div>
              </div>
            </li>
          ) : (
            <li className="right">
              <NavLink
                className="login-btn"
                to="/dashboard"
                onClick={closeMobileMenu}
              >
                Login
              </NavLink>
            </li>
          )}
        </ul>
      </nav>
    </>
  );
}
