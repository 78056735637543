import { useContext, useState, useEffect } from "react";
import { UserContext } from "../utils/context/userContext";
import { useFetchGuildBot } from "./../utils/hooks/useFetchGuildBot";
import { useNavigate, useParams } from "react-router-dom";
import { submitWelcomerForm } from "../utils/api";
import CardEditor from "../components/CardEditor/CardEditor";



export default function CardEdit() {
  const { user, guilds, guildsLoading } = useContext(UserContext);
  const [guild, setGuild] = useState([]);
  const [formData, setFormData] = useState(null);
  const [channels, setChannels] = useState([]);
  const [formChanged, setFormChanged] = useState(false);
  const { botGuild, botGuildLoading } = useFetchGuildBot(
    window.location.pathname.split("/")[2]
  );
    
  const navigate = useNavigate();
    
    
    useEffect(() => {
      console.info(
        "If you find any bugs, please report them on the support server. https://welcomer.app/support"
      );

      if (!guildsLoading && !botGuildLoading) {
        if (!guilds) {
          console.log("no guilds");
          return navigate("/dashboard");
        }
        const guildId = window.location.pathname.split("/")[2];

        var guild = guilds.find((guild) => guild.id === guildId);

        if (!guild) {
          return navigate("/dashboard");
        }
        if (!user.loggedIn) {
          return navigate("/dashboard");
        }
        setGuild(guild);
        setFormData(botGuild);
        setChannels(botGuild?.channels);
      }
    }, [botGuildLoading, guilds]);


    return (
      <div className="container">
        Edit welcomer
        <CardEditor
          guild={guild}
          formData={formData}
          setFormData={setFormData}
          formChanged={formChanged}
          setFormChanged={setFormChanged}
          channels={channels}
          submit={submitWelcomerForm}
          user={user}
        />
      </div>
    );
}
