import React from 'react'
import { Link } from 'react-router-dom';
import styles from './ModulesList.module.css'

export default function ModulesList() {
  return (
    <>
      <ul className={styles.container}>
        <Link to="welcomer">
          <li className={styles.box}>
            <img
              className={styles.image}
              src="/img/welcome.svg"
              alt="welcome"
            />
            <div className={styles.class_module}>Welcomer</div>
          </li>
        </Link>
        <Link to="goodbyeer">
          <li className={styles.box}>
            <img
              className={styles.image}
              src="/img/goodbye.svg"
              alt="welcome"
            />
            <div className={styles.class_module}>Goodbyer</div>
          </li>
        </Link>
        <Link to="stats">
          <li className={styles.box}>
            <img className={styles.image} src="/img/stats.svg" alt="welcome" />
            <div className={styles.class_module}>Stats</div>
          </li>{" "}
        </Link>{" "}
        <Link to="invites">

        <li className={styles.box}>
          <img className={styles.image} src="/img/invites.svg" alt="welcome" />
          <div className={styles.class_module}>Invites</div>
        </li>
        </Link>
      </ul>
    </>
  );
}
