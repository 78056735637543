import React, { useState, useEffect, useContext } from "react";
import { UserContext } from "../utils/context/userContext";
import { redirectToAuth, getGuilds } from "../utils/api";
import { Link } from "react-router-dom";
import Loader from "../components/Loader/Loader";
import { useNavigate } from "react-router-dom";

export default function DashboardPage() {
  const navigate = useNavigate();
  const params = new URLSearchParams(window.location.search);
  const { user, loading, guilds, guildsLoading } = useContext(UserContext);
  const [loadings, setLoadings] = useState(true);
  const guildId = params.get("guild_id");
   

  var inviteLink;

  if (process.env.NODE_ENV === "production") {
    inviteLink =
      "https://discord.com/oauth2/authorize?client_id=1008260316192329749&scope=bot&permissions=2147533856&response_type=code&redirect_uri=https%3A%2F%2Fwelcomer.app%2Fdashboard";
  } else {
    inviteLink =
      "https://discord.com/oauth2/authorize?client_id=990166146944729118&scope=bot&permissions=8&response_type=code&redirect_uri=http%3A%2F%2Flocalhost%3A3000%2Fdashboard";
  }

  useEffect(() => {
    // document.title = "Dashboard | Welcomer";
    if (!loading && !guildsLoading) {
      if (!user.loggedIn) {
        redirectToAuth();
      } else {
        if(guildId){
            return navigate(`/dashboard/${guildId}`)
        }
        setLoadings(false);
        // });
      }
    }
  }, [loading, guildsLoading]);

  return (
    <>
      <Loader unload={!loadings} />
      <div className="container">
        <div className="dash-title">
          <h1>Select a server:</h1>
          <div className="guild-card-container">
            {guilds && guilds.length > 0 ? (
              guilds.map((guild, index) => {
                let guildIcon = guild.icon ? `https://cdn.discordapp.com/icons/${guild.id}/${guild.icon}.jpg?size=256` : "https://cdn.discordapp.com/attachments/989561903405760573/1140734674382110720/logo512.png";
                if (guild.mutual) {
                  return (
                    <div key={index} className="guild-container">
                      <div className="img-container">
                        <div
                          className="background-image"
                          style={{
                            backgroundImage: "url(" + guildIcon + ")",
                          }}
                        />
                        <div className="guild-icon">
                          {guild.icon ? (
                            <img
                              className="img-icon"
                              src={guildIcon}
                              alt="guild icon"
                            />
                          ) : (
                            <div className="img-icon icon-placeholder">
                              {guild.name[0]}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="guild-bottom">
                        <div className="guild-name">
                          <p className="guild-name-p">{guild.name}</p>
                        </div>
                        {/* make a button to join */}
                        <Link className="guild-link" to={guild.id}>
                          <div className="manage-guild">Manage</div>
                        </Link>
                      </div>
                    </div>
                  );
                } else {
                  return (
                    <div key={index} className="guild-container">
                      <div className="img-container">
                        <div
                          className="background-image"
                          style={{
                            backgroundImage: "url(" + guildIcon + ")",
                          }}
                        />
                        <div className="guild-icon">
                          {guild.icon ? (
                            <img
                              className="img-icon"
                              src={guildIcon}
                              alt="guild icon"
                            />
                          ) : (
                            <div className="img-icon icon-placeholder">
                              {guild.name[0]}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="guild-bottom">
                        <div className="guild-name">
                          <p className="guild-name-p">{guild.name}</p>
                        </div>
                        <a className="guild-link" href={`${inviteLink}&guild_id=${guild.id}`}>
                          <div className="invite-guild">Invite Bot</div>
                        </a>
                      </div>
                    </div>
                  );
                }
              })
            ) : (
              <div className="guild-notfound">
                <div className="guild-notfound">No guilds found</div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
