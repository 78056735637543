import React from "react";
import { useEffect } from "react";
import "./Redirect.css"

export default function Redirect(params) {

  useEffect(() => {
    window.location.href=params.link
  }, []);

  return (
    <div className="redirect-container">
      <img
        className="redirect-img"
        src="./img/surfing.svg"
        alt="Surfing Redirect"
      />
      <div className="text">
        <h2>Redirecting to {params.pageName}...</h2>
        <h3>
          Please{" "}
          <span>
            <a href={params.link}>click here</a>
                  </span>
                  {" "}
          if you are not redirected.
        </h3>
      </div>
    </div>
  );
}
