import { useEffect, useState } from "react";
import { getAuthStatus } from "../api";


export function useFetchUser() {
  const [user, setUser] = useState({
    loggedIn: false,
    username: undefined,
    id: undefined,
    avatar: undefined,
  });
  const [error, setError] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function fetchState() {
      setLoading(true);
      await getAuthStatus()
        .then((data) => {
        if (data.status !== 200) {
          setTimeout(() => {
            fetchState();
          }, 5000);
        } 
          else {
            setUser(data.data);
            setLoading(false);
          }
        })
        .catch((err) => {
          console.log(err);
          setError(err);
          setTimeout(() => {
            fetchState();
          }, 5000);
        })
    }
    fetchState();
  }, []);
  return { user, error, loading };
}
