import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

export default function MenuPage() {
  useEffect(() => {
    // document.title = "Menu | Welcomer";
  });

  return (
    <>
      <div className="main">
        <div className="main-container">
          <div className="main-page-el">
            <header className="head-title">
              <h1>Welcomer,</h1>
              <h1>The best way to welcome users !</h1>
            </header>
            <div className="description">
              <p>
                Configure welcome message, image, stats and much more with the
                more easy-to-use dashbaord
              </p>
            </div>
            <div className="interaction-buttons">
              <div className="add-bot-btn">
                <Link to={"/dashboard"}>Add To Discord</Link>
              </div>
              <div className="features-btn">
                <a href="https://imperiator.gitbook.io/welcomer-bot/">
                  See features
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="logo-img-container">
          <div className="bg-logo-img">
            <img src="./logo_red.png" alt="logo-welcomer" />
          </div>
        </div>
      </div>
    </>
  );
}
