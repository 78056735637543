import { useEffect, useState } from "react";
import { getGuildBot, redirectToAuth } from "../api";

export function useFetchGuildBot(id) {
  const [botGuild, setGuilds] = useState();
  const [error, setError] = useState();
  const [botGuildLoading, setBotGuildLoading] = useState(true);

  useEffect(() => {
    async function fetchGuilds(id) {
      setBotGuildLoading(true);
      await getGuildBot(id)
        .then((data) => {
            setGuilds(data.data);
            setBotGuildLoading(false);
        })
        .catch((err) => {
          if (err.response.status === 401) {
            setBotGuildLoading(false);
          } else {
            setBotGuildLoading(true)
            setError(err);
            setTimeout(() => {
              fetchGuilds(id);
            }, 5000);
          }
        })
    }
    fetchGuilds(id);
  }, []);
  return { botGuild, error, botGuildLoading };
}
