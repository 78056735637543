import { useEffect, useState } from "react";
import { getGuilds, redirectToAuth } from "../api";

export function useFetchGuilds() {
  const [guilds, setGuilds] = useState();
  const [error, setError] = useState();
  const [guildsLoading, setLoading] = useState(true);

  useEffect(() => {
    async function fetchGuilds() {
      setLoading(true);
      await getGuilds()
        .then((data) => {
            setGuilds(data.data.guilds);
            setLoading(false);
        })
        .catch((err) => {

          setError(err);
          if (err.response.status === 401) {
            setLoading(false);
          } else {  
            setLoading(true)
            setTimeout(() => {
              fetchGuilds()
            }, 5000);
          }
        })
    }
    fetchGuilds();
  }, []);
  return { guilds, error, guildsLoading };
}
