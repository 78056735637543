import styles from "./CardEditor.module.css";

export default function CardEditor({
  guild,
  formData,
  channels,
  setFormData,
  formChanged,
  setFormChanged,
  submit,
  user,
}) {
  return (
    <div className={styles.container}>
      <div className={styles.message}>
        <div className={styles.messager}>
          <div className={styles.messagerIcon}></div>
          <span className={styles.messagerName}>Welcomer</span>
          <span className={styles.botTag}>✓ BOT</span>
        </div>
        <div className={styles.messageContent}>
          Hi @Clement, welcome to Welcomer - Support !
        </div>
      </div>
      <div className={styles.msgbar}>You can't write there !</div>
    </div>
  );
}
