import { useRef, useEffect } from "react";
import drawCanvas from "../../../utils/functions/drawCanvas";

export default function Goodbyeer({
  guild,
  formData,
  channels,
  setFormData,
  formChanged,
  setFormChanged,
  submit,
  user,
}) {
  const goodbyeCanvasRef = useRef(null);

  useEffect(() => {
    if (!formData) return;

    if (formData?.goodbyeer.enabled) {
      loadBackground();
    }
  }, [formData]);

  function loadBackground() {
    const image = new Image();

    var props = "no data";
    const defaultImage =
      "https://cdn.discordapp.com/attachments/830740575082774532/989455827842785310/adrian-infernus-GLf7bAwCdYg-unsplash.jpg";

    props = formData?.goodbyeer.background;

    if (props && props?.startsWith("https://" || "http://")) {
      image.src = props;

      image.onerror = () => {
        image.src = defaultImage;
      };
    } else {
      image.src = defaultImage;
    }
    image.onload = () => {
      loadPfp(image);
    };
  }

  function loadPfp(image) {
    var pfp = new Image();
    if (user.avatar) {
      pfp.src = `
      https://cdn.discordapp.com/avatars/${user.id}/${user.avatar}?size=256
    `;
    } else {
      pfp.src = "https://cdn.discordapp.com/embed/avatars/0.png";
    }
    pfp.onload = () => {
      drawCanvas(goodbyeCanvasRef, formData?.goodbyeer, image, pfp, user, guild);
    };
  }

  let fontSizes = [];
  for (let i = 30; i < 61; i++) {
    fontSizes.push(i);
  }

  function changeGoodbyeSwitchState(e) {
    let props = { ...formData?.goodbyeer };
    props[e.target.name] = !props[e.target.name];
    setFormData({ ...formData, goodbyeer: props });
    setFormChanged(true);
  }

  function changeNestedGoodbyeerSwitchState(e) {
    let props = { ...formData?.goodbyeer.embed };
    var name = e.target.name;
    props[name] = !props[name];
    setFormData({ ...formData, ...(formData.goodbyeer.embed = props) });
    setFormChanged(true);
  }

  function handleGoodbyeNestedChange(e) {
    let props = { ...formData?.goodbyeer.embed };
    var name = e.target.name;
    props[name] = e.target.value;
    setFormData({ ...formData, ...(formData.goodbyeer.embed = props) });
    setFormChanged(true);
  }

  function handleGoodbyeChange(e) {
    let props = { ...formData?.goodbyeer };
    props[e.target.name] = e.target.value;
    setFormData({ ...formData, goodbyeer: props });
    setFormChanged(true);
  }

  function handleSubmit(e) {
    e.preventDefault();
    submit(formData, guild.id).then((res) => {
      if (res.status === 200) {
        setFormChanged(false);
      }
    });
  }

  return (
    <div>
      <div className="guildCategory">
        <div className="guildCategory-title">Goodbye Users</div>
        <hr className="guild-hr" />
        <form
          method="post"
          action={`/api/guilds/${guild.id}`}
          // onSubmit={handleSubmit}
        >
          <div className="guildCategory-content" style={
            formChanged ? {"margin-bottom": "100px"}: {}
          }>
            <div className="enable">
              <div className="enable-title">
                Goodbye Status:{" "}
                {formData?.goodbyeer.enabled ? (
                  // colorise the text depending on the status
                  <span className="enable-status-enabled">Enabled</span>
                ) : (
                  <span className="enable-status-disabled">Disabled</span>
                )}
              </div>
              <div className="enable-button">
                <label className="switch">
                  <input
                    name="enabled"
                    className="switch"
                    type="checkbox"
                    checked={formData?.goodbyeer.enabled}
                    onChange={changeGoodbyeSwitchState}
                  />
                  <span className="slider round" />
                </label>
              </div>
            </div>
            <div
              className="enable-content"
              style={
                formData?.goodbyeer.enabled
                  ? { display: "block" }
                  : {
                      display: "none",
                    }
              }
            >
              <div className="enable-content-title">Goodbye Message:</div>
              <div className="welcomemsg-text">
                <div className="textspace">
                  <div className="textarea-label">
                    <label htmlFor="welcomemsg">Text Message:</label>
                  </div>
                  <textarea
                    className="welcomemsg-text-input"
                    name="textMessage"
                    required
                    disabled={!formData?.goodbyeer.enabled}
                    value={formData?.goodbyeer.textMessage}
                    onChange={handleGoodbyeChange}
                  />
                  <div className="textarea-label">
                    <label htmlFor="welcomemsg">Image Message:</label>
                  </div>
                  <textarea
                    className="welcomemsg-text-input textinput2"
                    name="message"
                    required
                    disabled={!formData?.goodbyeer.enabled}
                    value={formData?.goodbyeer.message}
                    onChange={handleGoodbyeChange}
                  />
                </div>
                <div className="formatdataHelper">
                  <ul className="fomatHelperUl">
                    <li>
                      <b>{"{user}"}</b> - The user&#39;s tag eg: @user#1234
                    </li>
                    <li>
                      <b>{"{username}"}</b> - The user&#39;s name
                    </li>
                    <li>
                      <b>{"{userid}"}</b> - The user&#39;s id
                    </li>
                    <li>
                      <b>{"{guild}"}</b> - The guild name
                    </li>
                    <li>
                      <b>{"{guildid}"}</b> - The guild id
                    </li>
                    <li>
                      <b>{"{membercount}"}</b> - The number of members in the
                      guild
                    </li>
                  </ul>
                </div>
              </div>
              <div className="channel">
                <div className="channel-title">Goodbye Channel:</div>
                <div className="channel-select">
                  <select
                    className="channel-select-input"
                    name="channel"
                    required
                    disabled={!formData?.goodbyeer.enabled}
                    value={formData?.goodbyeer.channel}
                    onChange={handleGoodbyeChange}
                  >
                    <option value="" disabled selected>
                      {" "}
                      - Select a channel
                    </option>

                    { channels?.map((channel) => (
                      <option key={channel.id} value={channel.id}>
                        #{channel.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="image-enable">
                <div className="enable-title">
                  Image Showing Status:{" "}
                  {formData?.goodbyeer.imageEnabled ? (
                    // colorise the text depending on the status
                    <span className="enable-status-enabled">Enabled</span>
                  ) : (
                    <span className="enable-status-disabled">Disabled</span>
                  )}
                </div>
                <div className="enable-button">
                  <label className="switch">
                    <input
                      name="imageEnabled"
                      className="switch"
                      type="checkbox"
                      checked={formData?.goodbyeer.imageEnabled}
                      onChange={changeGoodbyeSwitchState}
                    />
                    <span className="slider round" />
                  </label>
                </div>
              </div>
              <div
                className="image-preview-component"
                style={
                  formData?.goodbyeer.imageEnabled
                    ? { display: "block" }
                    : { display: "none" }
                }
              >
                <div className="image-preview-component-title">
                  Image Preview:
                </div>
                <div className="canva">
                  <canvas
                    className="canvas"
                    ref={goodbyeCanvasRef}
                    width={1024}
                    height={450}
                  />
                </div>
                <div className="image-preview-component-title">
                  Customize Image:
                </div>
                <div className="image-preview-component-selectors">
                  <div className="image-preview-component-selectors-fontSize">
                    <select
                      name="fontSize"
                      id="fontSize"
                      disabled={!formData?.goodbyeer.enabled}
                      className="fontSizes-select"
                      onChange={handleGoodbyeChange}
                      value={formData?.goodbyeer.fontSize}
                    >
                      {fontSizes.map((fontSize) => (
                        <option key={fontSize} value={fontSize}>
                          Font Size: {fontSize}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="image-preview-component-selectors-color">
                    <label htmlFor="color" className="colorLabel">
                      Color:
                    </label>
                    <input
                      type="color"
                      name="color"
                      id="color"
                      placeholder="Color"
                      disabled={!formData?.goodbyeer.enabled}
                      className="color-select"
                      onChange={handleGoodbyeChange}
                      value={formData?.goodbyeer.color}
                    />
                  </div>
                </div>
                <div className="preview-component-background">
                  <div className="preview-component-background-title">
                    Background URL:
                  </div>
                  <div className="preview-component-background-input">
                    <input
                      className="preview-component-background-input-input"
                      name="background"
                      id="background"
                      disabled={!formData?.goodbyeer.enabled}
                      placeholder="Background URL"
                      onChange={handleGoodbyeChange}
                      value={formData?.goodbyeer.background}
                    />
                  </div>
                </div>
              </div>
              <div className="embed-enabled-cat">
                <div className="enable-sec">
                  <div className="enable-title">
                    Embeded Message:{" "}
                    {formData?.goodbyeer.embed.enabled ? (
                      // colorise the text depending on the status
                      <span className="enable-status-enabled">Enabled</span>
                    ) : (
                      <span className="enable-status-disabled">Disabled</span>
                    )}
                  </div>
                  <div className="enable-button">
                    <label className="switch">
                      <input
                        name="enabled"
                        className="switch"
                        type="checkbox"
                        checked={formData?.goodbyeer.embed.enabled}
                        onChange={changeNestedGoodbyeerSwitchState}
                      />
                      <span className="slider round" />
                    </label>
                  </div>
                </div>
                <div
                  className="embed-color-selector"
                  style={
                    formData?.goodbyeer.embed.enabled
                      ? { display: "flex" }
                      : {
                          display: "none",
                        }
                  }
                >
                  <label htmlFor="color" className="colorLabel-embed">
                    Embed Color:
                  </label>
                  <input
                    type="color"
                    name="color"
                    id="color"
                    placeholder="Color"
                    disabled={!formData?.goodbyeer.embed.enabled}
                    className="color-select"
                    onChange={handleGoodbyeNestedChange}
                    value={formData?.goodbyeer.embed.color}
                  />
                </div>
              </div>
            </div>
          </div>
          <div
            className={
              "guildCategory-submit" + " " + (formChanged ? "form-changed" : "")
            }
          >
            <p>Changes asn&#39;t saved yet! Click to save.</p>
            <input
              className="guildCategory-submit-button"
              value={formChanged ? "Save Changes" : "..."}
              type="submit"
              onClick={handleSubmit}
            />
          </div>
        </form>
      </div>
    </div>
  );
}
