import React from "react";

export default function LegalPage() {
  return (
    <div>
      <div className="container">
        <p>
          <h2>Terms of Service</h2>
          <br />
          <br />
          <h3>Usage Agreement</h3>
          <br />
          <p>
            By inviting the bot and using its features (commands, welcome system
            and all of the others) are you agreeing to the below mentioned Terms
            and Privacy Policy (Policy) of the bot.
          </p>
          <p>
            You acknowledge that you have the priviledge to use the bot freely
            on any Discord Server (Server) you share with it, that you can
            invite it to any Server that you have "Manage Server" rights for and
            that this priviledge might get revoked for you, if you&#39;re
            subject of breaking the terms and/or policy of this bot, or the
            Terms of Service, Privacy Policy and/or Community Guidelines of
            Discord Inc.
          </p>
          <p>
            Through Inviting the bot may it collect specific data as described
            in its Policy. The intended usage of this data is for core
            functionalities of the bot such as command handling, guild-specific
            settings, the Welcoming/Goodbying System and live stats System.
          </p>
          <br />
          <h3>Intended Age</h3>
          <br />
          <p>
            The bot may not be used by individuals under the minimal age
            described in Discord&#39;s Terms of Service. Background Image must
            be non NSFW (Not Safe For Work) to allow everyone to use the bot.
          </p>
          <br />
          <h3>Affiliation</h3>
          <br />
          <p>
            The Bot is not affiliated with, supported or made by Discord Inc.
            Any direct connection to Discord or any of its Trademark objects is
            purely coincidental. We do not claim to have the copyright ownership
            of any of Discord&#39;s assets, trademarks or other intellectual
            property.
          </p>
          <br />
          <h3>Liability</h3>
          <br />
          <p>
            The owner of the bot may not be made liable for individuals breaking
            these Terms at any given time. He has faith in the end users being
            truthfull about their information and not missusing this bot or The
            Services of Discord Inc in a malicious way.
          </p>
          <p>
            We reserve the right to update these terms at our own discretion,
            giving you a 1-Week (7 days) period to opt out of these terms if
            you&#39;re not agreeing with the new changes. You may opt out by
            Removing the bot from any Server you have the rights for.
          </p>
          <br />
          <h3>Contact</h3>
          <br />
          <p>
            People may get in contact through the{" "}
            <a href="/support">official Support Discord of the Bot</a>. Other
            ways of support may be provided but aren&#39;t guaranteed.
          </p>
          <br />
          <br />

          <h2>Privacy Policy</h2>
          <br />
          <br />
          <h3>Usage of Data</h3>
          <br />
          <p>
            The bot may use stored data, as defined below, for different
            features including but not limited to: Welcoming joining users (when
            enabled) / Goodbying leaving users (when enabled), command handling
            and dashbaord configuration. No usage of data outside of the
            aformentioned cases will happen and the data is not shared with any
            3rd-party site or service.
          </p>
          <br />
          <h3>Stored Information</h3>
          <br />
          <p>
            The bot may store the following information automatically when being
            invited to a new Discord Server:
          </p>

          <ul>
            <li>id with the Server&#39;s ID as value.</li>
            <li>name with the Server&#39;s Name as value.</li>
            <li>icon with the Server&#39;s Icon as value.</li>
            <li>channels with the Server&#39;s Text Channels as value.</li>
            <li>
              bot permissions with the Server&#39;s Bot Permissions as value.
            </li>
            <li>
              Auto generated data for the Welcome/Goodbying System, not relative
              of guild data.
            </li>
          </ul>
          <p>
            The server may store the following information automatically when
            loging in to the dashboard:
          </p>
          <ul>
            <li>id with the user&#39;s ID as value</li>
            <li>accestoken with the user&#39;s AccesToken as value</li>
          </ul>
          <p>
            No other information outside of the above mentioned one will be
            stored.
          </p>

          <br />
          <h3>Updating Data</h3>
          <br />
          <p>
            The data may be updated when using dashboard configuration page. No
            other actions may update the stored information at any given time.
          </p>
          <br />
          <h3>Temporarely stored Information</h3>
          <br />
          <p>
            The Bot may keep the stored information in an internal cacheing
            mechanic for a certain amount of time. After this time period, the
            cached information will be dropped and only be re-added when
            required.
          </p>
          <p>
            Data may be dropped from cache pre-maturely through actions such as
            removing the bot from the Server.
          </p>
          <br />
          <h3>Removal of Data</h3>
          <h4>Automatic removal</h4>
          <p>
            Stored Data can be removed automatically through means of removing
            the bot from a Server. This can be achieved by either kicking or
            banning the bot from the server. Re-inviting the bot will add the
            same default values, as mentioned above, back to the bot&#39;s
            database.
          </p>
          <h4>Manual removal</h4>
          <br />
          <p>
            Manual removal of the data can be requested through support server.
          </p>
          <p>
            For security reasons will we ask you to provide us with proof of
            ownership of the server, that you wish the data to be removed of.
            Only a server owner may request manual removal of data and
            requesting it will result in the bot being removed from the server,
            if still present on it.
          </p>
        </p>
      </div>
    </div>
  );
}
