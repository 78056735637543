import React from 'react'
import { useState, useEffect } from 'react'
import styles from "./GuildIcon.module.css"


export default function GuildIcon({ guild }) {
  
  const [icon, setIcon] = useState()
  // console.log(guild)

  useEffect(() => {
    let guildIcon = `https://cdn.discordapp.com/icons/${guild?.id}/${guild?.icon}.jpg?size=256`;

    if (guild?.icon !== null) {
      setIcon(<img className={styles.image} src={guildIcon} alt="Guild Icon"/>)
    } else {
      setIcon(<div className="img-icon icon-placeholder">{guild.name[0]}</div>);
    }
  }, [guild])
  return (
    <>{icon}</>
  )
}
