import { useState, useEffect } from "react";
import styles from "./Dockers.module.css";
import GuildIcon from "../../Image/GuildIcon";
import { useContext } from "react";
import { UserContext } from "../../../utils/context/userContext";
import Loader from "../../Loader/Loader";
import cn from "classnames";
import { NavLink, useParams } from "react-router-dom";
import { Logout } from "../../../utils/api";

export default function Dockers({ botGuild,botGuildLoading, children, guild }) {
  const [openState, setOpenstate] = useState(true);
  const [servers, setServers] = useState({
    current: {},
  });
  const [contextClick, setContextClick] = useState(false);


  const params = useParams()

  const { user, guilds } = useContext(UserContext);
    const closeContextMenu = () => {
      setContextClick(!contextClick);
    };

  useEffect(() => {
    setServers({
      current: botGuild? botGuild : guild,
      guilds,
    });
    // console.log("current", botGuild);
  }, [guilds, botGuild, botGuildLoading, guild]);

  function handleMenuBtnClick() {
    setOpenstate(!openState);
  }

  return (
    <>
      <Loader unload={user.loggedIn && !botGuildLoading } />
      <div className={styles.topbar}>
        <div className={styles.menu_opener}>
          <svg
            className={styles.openmenu}
            width="35"
            height="35"
            viewBox="0 0 24 24"
            fill="none"
            onClick={handleMenuBtnClick}
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M4 6C4 5.44772 4.44772 5 5 5H19C19.5523 5 20 5.44772 20 6C20 6.55228 19.5523 7 19 7H5C4.44772 7 4 6.55228 4 6Z"
              fill="currentColor"
            />
            <path
              d="M4 18C4 17.4477 4.44772 17 5 17H19C19.5523 17 20 17.4477 20 18C20 18.5523 19.5523 19 19 19H5C4.44772 19 4 18.5523 4 18Z"
              fill="currentColor"
            />
            <path
              d="M11 11C10.4477 11 10 11.4477 10 12C10 12.5523 10.4477 13 11 13H19C19.5523 13 20 12.5523 20 12C20 11.4477 19.5523 11 19 11H11Z"
              fill="currentColor"
            />
          </svg>
          <div className={styles.div}>
            <img
              src="/favicon.ico"
              alt="welcomer logo"
              className={styles.logo}
            />
            <h3>Welcomer Dash</h3>
          </div>
          <div className={styles.usermenu}>
            {user.loggedIn ? (
              <li className={styles.avatar}>
                <div className="" onClick={closeContextMenu}>
                  {user.avatar ? (
                    <img
                      className="avatar-img"
                      src={
                        "https://cdn.discordapp.com/avatars/" +
                        user.id +
                        "/" +
                        user.avatar +
                        "?size=256"
                      }
                      alt="avatar"
                    />
                  ) : (
                    <div className="avatar-img nav-icon-placeholder">
                      {user.username[0]}
                    </div>
                  )}
                </div>
              </li>
            ) : (
              <span></span>
            )}
          </div>
          <div
            className={
              contextClick
                ? "dock-usermenu dock-usermenu-active"
                : "dock-usermenu"
            }
          >
            <ul>
              <li>
                <NavLink className="a-button" to={"/dashboard"}>
                  My Guilds
                </NavLink>
              </li>
              <li>
                <NavLink className="a-button" to={"/"}>
                  Home Page
                </NavLink>
              </li>
              <li className="logout" onClick={Logout}>
                Logout
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className={styles.wrapper}>
        <div className={cn(styles.sidebar, { [styles.active]: openState })}>
          <div className={styles.server_list}>
            <div className={styles.current_server}>
              <div className={styles.current_logo}>
                <GuildIcon guild={servers?.current} />
              </div>
              <div className={styles.guild_name}>{servers?.current?.name}</div>
            </div>
          </div>
          <div className={styles.modules_list}>
            <ul>
              <li className={styles.li}>
                <NavLink
                  className={styles.navlink}
                  to={`/dashboard/${params.id}/welcomer`}
                  onClick={handleMenuBtnClick}


                >
                  <div className={styles.icon}>
                    <svg
                      className={styles.svg}
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M12 6C12.5523 6 13 6.44772 13 7V11H17C17.5523 11 18 11.4477 18 12C18 12.5523 17.5523 13 17 13H13V17C13 17.5523 12.5523 18 12 18C11.4477 18 11 17.5523 11 17V13H7C6.44772 13 6 12.5523 6 12C6 11.4477 6.44772 11 7 11H11V7C11 6.44772 11.4477 6 12 6Z"
                        fill="currentColor"
                      />
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M5 22C3.34315 22 2 20.6569 2 19V5C2 3.34315 3.34315 2 5 2H19C20.6569 2 22 3.34315 22 5V19C22 20.6569 20.6569 22 19 22H5ZM4 19C4 19.5523 4.44772 20 5 20H19C19.5523 20 20 19.5523 20 19V5C20 4.44772 19.5523 4 19 4H5C4.44772 4 4 4.44772 4 5V19Z"
                        fill="currentColor"
                      />
                    </svg>
                  </div>
                  <span className={styles.module_name}>Welcomer</span>
                </NavLink>
              </li>
              <li className={styles.li}>
                <NavLink
                  className={styles.navlink}
                  to={`/dashboard/${params.id}/goodbyeer`}
                  onClick={handleMenuBtnClick}

                >
                  <div className={styles.icon}>
                    <svg
                      className={styles.svg}
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M8 11C10.2091 11 12 9.20914 12 7C12 4.79086 10.2091 3 8 3C5.79086 3 4 4.79086 4 7C4 9.20914 5.79086 11 8 11ZM8 9C9.10457 9 10 8.10457 10 7C10 5.89543 9.10457 5 8 5C6.89543 5 6 5.89543 6 7C6 8.10457 6.89543 9 8 9Z"
                        fill="currentColor"
                      />
                      <path
                        d="M11 14C11.5523 14 12 14.4477 12 15V21H14V15C14 13.3431 12.6569 12 11 12H5C3.34315 12 2 13.3431 2 15V21H4V15C4 14.4477 4.44772 14 5 14H11Z"
                        fill="currentColor"
                      />
                      <path d="M22 9H16V11H22V9Z" fill="currentColor" />
                    </svg>
                  </div>
                  <div className={styles.module_name}>Goodbyeer</div>
                </NavLink>
              </li>
              <li className={styles.li}>
                <NavLink
                  className={styles.navlink}
                  to={`/dashboard/${params.id}/stats`}
                  onClick={handleMenuBtnClick}

                >
                  <div className={styles.icon}>
                    <svg
                      className={styles.svg}
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M14 13.9633H16V7.96331H10V9.96331H12.5858L7.25623 15.2929L8.67044 16.7071L14 11.3775L14 13.9633Z"
                        fill="currentColor"
                      />
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M1 12C1 5.92487 5.92487 1 12 1C18.0751 1 23 5.92487 23 12C23 18.0751 18.0751 23 12 23C5.92487 23 1 18.0751 1 12ZM3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12Z"
                        fill="currentColor"
                      />
                    </svg>
                  </div>
                  <div className={styles.module_name}>Stats</div>
                </NavLink>
              </li>
              <li className={styles.li}>
                <NavLink
                  className={styles.navlink}
                  to={`/dashboard/${params.id}/invites`}
                  onClick={handleMenuBtnClick}


                >
                  <div className={styles.icon}>
                    <svg
                      className={styles.svg}
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M3.00977 5.83789C3.00977 5.28561 3.45748 4.83789 4.00977 4.83789H20C20.5523 4.83789 21 5.28561 21 5.83789V17.1621C21 18.2667 20.1046 19.1621 19 19.1621H5C3.89543 19.1621 3 18.2667 3 17.1621V6.16211C3 6.11449 3.00333 6.06765 3.00977 6.0218V5.83789ZM5 8.06165V17.1621H19V8.06199L14.1215 12.9405C12.9499 14.1121 11.0504 14.1121 9.87885 12.9405L5 8.06165ZM6.57232 6.80554H17.428L12.7073 11.5263C12.3168 11.9168 11.6836 11.9168 11.2931 11.5263L6.57232 6.80554Z"
                        fill="currentColor"
                      />
                    </svg>
                  </div>
                  <div className={styles.module_name}>Invites</div>
                </NavLink>
              </li>
            </ul>
          </div>
        </div>
        <div
          id="test"
          onClick={function () {
            if (window.innerWidth < 815 && openState) {
              setOpenstate(false);
            }
          }}
          className={cn(styles.module_container, {
            [styles.active_module]: openState,
          })}
        >
          {children}
        </div>
      </div>
    </>
  );
}
