import React, { useContext, useState, useRef } from "react";
import { UserContext } from "../utils/context/userContext";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useFetchGuildBot } from "./../utils/hooks/useFetchGuildBot";
// import { submitWelcomerForm } from "../utils/api";
import Loader from "../components/Loader/Loader";
import drawCanvas from "../utils/functions/drawCanvas";
import Dockers from "../components/GuildModules/Dockers/Dockers";
import ModulesList from "../components/ModulesList/ModulesList";


export default function GuildPage() {
  const { user, guilds, guildsLoading } = useContext(UserContext);
  const [guild, setGuild] = useState([]);
  const [formData, setFormData] = useState(null);
  // const [channels, setChannels] = useState([]);
  // const [formChanged, setFormChanged] = useState(false);
  const { botGuild, botGuildLoading } = useFetchGuildBot(
    window.location.pathname.split("/")[2]
  );
  // const welcomeCanvasRef = useRef(null);
  // const goodbyeCanvasRef = useRef(null);

  const navigate = useNavigate();
  useEffect(() => {
    console.info(
      "If you find any bugs, please report them on the support server. https://welcomer.app/support"
    );

    if (!guilds) {
      return navigate("/dashboard");
    }
    if (!guildsLoading && !botGuildLoading) {
      const guildId = window.location.pathname.split("/")[2];

      var guild = guilds.find((guild) => guild.id === guildId);

      if (!guild) {
        return navigate("/dashboard");
      }
      if (!user.loggedIn) {
        return navigate("/dashboard")
      }
      setGuild(guild);
      setFormData(botGuild);
      // setChannels(botGuild?.channels);
    }
  }, [botGuildLoading, guilds, botGuild]);

  //Load image if module is enabled

  // useEffect(() => {
  //   if (!formData) return;

  //   if (formData?.welcomer.enabled) {
  //     loadBackground("welcome");
  //   }
  //   if (formData?.goodbyeer.enabled) {
  //     loadBackground("goodbye");
  //   }
  // }, [formData]);

  // let fontSizes = [];
  // for (let i = 30; i < 61; i++) {
  //   fontSizes.push(i);
  // }

  // function loadBackground(type) {
  //   const image = new Image();

  //   var props = "no data";
  //   const defaultImage =
  //     "https://cdn.discordapp.com/attachments/830740575082774532/989455827842785310/adrian-infernus-GLf7bAwCdYg-unsplash.jpg";
  //   if (type === "welcome") {
  //     props = formData?.welcomer.background;
  //   } else {
  //     props = formData?.goodbyeer.background;
  //   }
  //   if (props.startsWith("https://" || "http://")) {
  //     image.src = props;

  //     image.onerror = () => {
  //       image.src = defaultImage;
  //     };
  //   } else {
  //     image.src = defaultImage;
  //   }
  //   image.onload = () => {
  //     loadPfp(type, image);
  //   };
  // }

  // function loadPfp(type, image) {
  //   var pfp = new Image();
  //   if (user.avatar) {
  //     pfp.src = `
  //     https://cdn.discordapp.com/avatars/${user.id}/${user.avatar}?size=256
  //   `;
  //   } else {
  //     pfp.src = "https://img.imperiator.tk/1%20%281%29.png";
  //   }
  //   pfp.onload = () => {
  //     if (type === "welcome") {
  //       drawCanvas(
  //         welcomeCanvasRef,
  //         formData?.welcomer,
  //         image,
  //         pfp,
  //         user,
  //         guild
  //       );
  //     } else {
  //       drawCanvas(
  //         goodbyeCanvasRef,
  //         formData?.goodbyeer,
  //         image,
  //         pfp,
  //         user,
  //         guild
  //       );
  //     }
  //   };
  // }

  // function changeWelcSwitchState(e) {
  //   let props = { ...formData?.welcomer };
  //   var name = e.target.name;
  //   props[name] = !props[name];
  //   setFormData({ ...formData, welcomer: props });
  //   setFormChanged(true);
  // }

  // function changeNestedSwitchState(e) {
  //   let props = { ...formData?.welcomer.embed };
  //   var name = e.target.name;
  //   props[name] = !props[name];
  //   setFormData({ ...formData, ...(formData.welcomer.embed = props) });
  //   setFormChanged(true);
  // }
  // function changeNestedGoodbyeerSwitchState(e) {
  //   let props = { ...formData?.goodbyeer.embed };
  //   var name = e.target.name;
  //   props[name] = !props[name];
  //   setFormData({ ...formData, ...(formData.goodbyeer.embed = props) });
  //   setFormChanged(true);
  // }

  // function handleWelcNestedChange(e) {
  //   let props = { ...formData?.welcomer.embed };
  //   var name = e.target.name;
  //   props[name] = e.target.value;
  //   setFormData({ ...formData, ...(formData.welcomer.embed = props) });
  //   setFormChanged(true);
  // }

  // function handleGoodbyeNestedChange(e) {
  //   let props = { ...formData?.goodbyeer.embed };
  //   var name = e.target.name;
  //   props[name] = e.target.value;
  //   setFormData({ ...formData, ...(formData.goodbyeer.embed = props) });
  //   setFormChanged(true);
  // }

  // function changeGoodbyeSwitchState(e) {
  //   let props = { ...formData?.goodbyeer };
  //   props[e.target.name] = !props[e.target.name];
  //   setFormData({ ...formData, goodbyeer: props });
  //   setFormChanged(true);
  // }

  // function handleWelcChange(e) {
  //   let props = { ...formData?.welcomer };
  //   props[e.target.name] = e.target.value;
  //   setFormData({ ...formData, welcomer: props });
  //   setFormChanged(true);
  // }

  // function handleGoodbyeChange(e) {
  //   let props = { ...formData?.goodbyeer };
  //   props[e.target.name] = e.target.value;
  //   setFormData({ ...formData, goodbyeer: props });
  //   setFormChanged(true);
  // }

  // function handleSubmit(e) {
  //   e.preventDefault();
  //   submitWelcomerForm(formData, guild.id).then((res) => {
  //     if (res.status === 200) {
  //       setFormChanged(false);
  //     }
  //   });
  // }

  return (
    <>
      <Loader unload={guild !== undefined && formData !== null} />
      <Dockers guilds={guilds} guild={formData}>
        <ModulesList/>
      </Dockers>
    </>
  );
}
