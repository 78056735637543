import React from "react";
import { useEffect } from "react";
import Redirect from "../components/Redirect/Redirect";

export default function InvitePage() {
  return (
    <div className="container">
      <Redirect
        link={
          "https://discord.com/oauth2/authorize?client_id=1008260316192329749&scope=bot"
        }
        pageName={"Invite Page"}
      />
    </div>
  );
}
