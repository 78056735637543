import { useEffect, useRef } from "react";
import drawCanvas from "../../../utils/functions/drawCanvas";


export default function Welcomer({guild, formData, channels, setFormData, formChanged, setFormChanged, submit, user}) {

    const welcomeCanvasRef = useRef(null);

    useEffect(() => {
        if (!formData) return;

        if (formData?.welcomer.enabled) {
            loadBackground("welcome");
        }
    }, [formData, guild, channels, user]);

    function loadBackground(type) {
        const image = new Image();

        var props = "no data";
        const defaultImage =
            "https://images.unsplash.com/photo-1554034483-04fda0d3507b?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D";

        props = formData?.welcomer.background;

        // //     find background object by its id and get its url
        // let background = formData.backgrounds.find((b) => Number(b.id) === props)?.url
        if (props && props?.startsWith("https://" || "http://")) {
            image.src = props;

            image.onerror = () => {
                image.src = defaultImage;
            };
        } else {
            image.src = defaultImage;
        }
        image.onload = () => {
            loadPfp(type, image);
        };
    }

    function loadPfp(type, image) {
        var pfp = new Image();
        if (user.avatar) {
            pfp.src = `
      https://cdn.discordapp.com/avatars/${user.id}/${user.avatar}?size=256
    `;
        } else {
            pfp.src = "https://cdn.discordapp.com/embed/avatars/0.png";
        }
        pfp.onload = () => {

            drawCanvas(
                welcomeCanvasRef,
                formData?.welcomer,
                image,
                pfp,
                user,
                guild
            );
        };
    }

    let fontSizes = [];
    for (let i = 30; i < 61; i++) {
        fontSizes.push(i);
    }

    function changeWelcSwitchState(e) {
        let props = {...formData?.welcomer};
        var name = e.target.name;
        props[name] = !props[name];
        setFormData({...formData, welcomer: props});
        setFormChanged(true);
    }

    function changeNestedSwitchState(e) {
        let props = {...formData?.welcomer.embed};
        var name = e.target.name;
        props[name] = !props[name];
        setFormData({...formData, ...(formData.welcomer.embed = props)});
        setFormChanged(true);
    }

    function handleWelcNestedChange(e) {
        let props = {...formData?.welcomer.embed};
        var name = e.target.name;
        props[name] = e.target.value;
        setFormData({...formData, ...(formData.welcomer.embed = props)});
        setFormChanged(true);
    }

    function handleWelcChange(e) {
        let props = {...formData?.welcomer};
        props[e.target.name] = e.target.value;
        setFormData({...formData, welcomer: props});
        setFormChanged(true);
    }

    function handleSubmit(e) {
        e.preventDefault();
        submit(formData, guild.id).then((res) => {
            if (res.status === 200) {
                setFormChanged(false);
            }
        });
    }

    return (
        <div>
            <div className="guildCategory">
                <div className="guildCategory-title">Welcome Users</div>
                <hr className="guild-hr"/>
                <form
                    method="post"
                    action={`/api/guilds/${guild?.id}`}
                    // onSubmit={handleSubmit}
                >
              <div className="guildCategory-content" style={
            formChanged ? {'margin-bottom': "100px "}: {}
          }>
                        <div className="enable">
                            <div className="enable-title">
                                Welcome Status:{" "}
                                {formData?.welcomer.enabled ? (
                                    // colorise the text depending on the status
                                    <span className="enable-status-enabled">Enabled</span>
                                ) : (
                                    <span className="enable-status-disabled">Disabled</span>
                                )}
                            </div>
                            <div className="enable-button">
                                <label className="switch">
                                    <input
                                        name="enabled"
                                        className="switch"
                                        type="checkbox"
                                        checked={formData?.welcomer.enabled}
                                        onChange={changeWelcSwitchState}
                                    />
                                    <span className="slider round"/>
                                </label>
                            </div>
                        </div>
                        <div
                            className="enable-content"
                            style={
                                formData?.welcomer.enabled
                                    ? {display: "block"}
                                    : {
                                        display: "none",
                                    }
                            }
                        >
                            <div className="enable-content-title">Welcome Message:</div>
                            <div className="welcomemsg-text">
                                <div className="textspace">
                                    <div className="textarea-label">
                                        <label htmlFor="welcomemsg">Text Message:</label>
                                    </div>
                                    <textarea
                                        className="welcomemsg-text-input"
                                        name="textMessage"
                                        required
                                        disabled={!formData?.welcomer.enabled}
                                        value={formData?.welcomer.textMessage}
                                        onChange={handleWelcChange}
                                    />
                                    <div className="textarea-label">
                                        <label htmlFor="welcomemsg">Image Message:</label>
                                    </div>
                                    <textarea
                                        className="welcomemsg-text-input textinput2"
                                        name="message"
                                        required
                                        disabled={!formData?.welcomer.enabled}
                                        value={formData?.welcomer.message}
                                        onChange={handleWelcChange}
                                    />
                                </div>
                                <div className="formatdataHelper">
                                    <ul className="fomatHelperUl">
                                        <li>
                                            <b>{"{user}"}</b> - The user&#39;s tag eg: @user#1234
                                        </li>
                                        <li>
                                            <b>{"{username}"}</b> - The user&#39;s name
                                        </li>
                                        <li>
                                            <b>{"{userid}"}</b> - The user&#39;s id
                                        </li>
                                        <li>
                                            <b>{"{guild}"}</b> - The guild name
                                        </li>
                                        <li>
                                            <b>{"{guildid}"}</b> - The guild id
                                        </li>
                                        <li>
                                            <b>{"{membercount}"}</b> - The number of members in the
                                            guild
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="channel">
                                <div className="channel-title">Welcome Channel:</div>
                                <div className="channel-select">
                                    <select
                                        className="channel-select-input"
                                        name="channel"
                                        required
                                        disabled={!formData?.welcomer.enabled}
                                        value={formData?.welcomer.channel}
                                        onChange={handleWelcChange}
                                    >
                                        <option defaultValue={""}>
                                            - Select a channel
                                        </option>
                                        {channels?.map((channel) => (
                                            <option key={channel?.id} value={channel?.id}>
                                                #{channel?.name}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                            <div className="image-enable">
                                <div className="enable-title">
                                    Image Showing Status:{" "}
                                    {formData?.welcomer.imageEnabled ? (
                                        // colorise the text depending on the status
                                        <span className="enable-status-enabled">Enabled</span>
                                    ) : (
                                        <span className="enable-status-disabled">Disabled</span>
                                    )}
                                </div>
                                <div className="enable-button">
                                    <label className="switch">
                                        <input
                                            name="imageEnabled"
                                            className="switch"
                                            type="checkbox"
                                            checked={formData?.welcomer.imageEnabled}
                                            onChange={changeWelcSwitchState}
                                        />
                                        <span className="slider round"/>
                                    </label>
                                </div>
                            </div>
                            <div
                                className="image-preview-component"
                                style={
                                    formData?.welcomer.imageEnabled
                                        ? {display: "block"}
                                        : {
                                            display: "none",
                                        }
                                }
                            >
                                <div className="image-preview-component-title">
                                    Image Preview:
                                </div>
                                <div className="canva">
                                    <canvas
                                        className="canvas"
                                        ref={welcomeCanvasRef}
                                        width={1024}
                                        height={450}
                                    />
                                </div>
                                <div className="image-preview-component-title">
                                    Customize Image:
                                </div>
                                <div className="image-preview-component-selectors">
                                    <div className="image-preview-component-selectors-fontSize">
                                        <select
                                            name="fontSize"
                                            id="fontSize"
                                            disabled={!formData?.welcomer.enabled}
                                            className="fontSizes-select"
                                            onChange={handleWelcChange}
                                            value={formData?.welcomer.fontSize}
                                        >
                                            {fontSizes.map((fontSize) => (
                                                <option key={fontSize} value={fontSize}>
                                                    Font Size: {fontSize}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="image-preview-component-selectors-color">
                                        <label htmlFor="color" className="colorLabel">
                                            Color:
                                        </label>
                                        <input
                                            type="color"
                                            name="color"
                                            id="color"
                                            placeholder="Color"
                                            disabled={!formData?.welcomer.enabled}
                                            className="color-select"
                                            onChange={handleWelcChange}
                                            value={formData?.welcomer.color}
                                        />
                                    </div>
                                </div>
                                <div className="preview-component-background">
                                    <div className="preview-component-background-title">
                                        {/* Background Gallery: */}
                                        Background URL:
                                    </div>
                                    <div className="preview-component-background-input">
                                        {/* {formData?.backgrounds?.map((background) => (
                                                <div className="preview-component-background-input-image">
                                                    <img
                                                        src={background.url}
                                                        alt="background"
                                                        className="preview-component-background-input-image-img"
                                                        onClick={() => {
                                                            setFormData({
                                                                ...formData,
                                                                welcomer: {
                                                                    ...formData.welcomer,
                                                                    background: Number(background.id),
                                                                },
                                                            });
                                                        }
                                                        }
                                                    />
                                                </div>
                                            )
                                        )} */}

                                          <input
                      className="preview-component-background-input-input"
                      name="background"
                      id="background"
                      disabled={!formData?.welcomer.enabled}
                      placeholder="Background URL"
                      onChange={handleWelcChange}
                      value={formData?.welcomer.background}
                    />
                                        
                                    </div>
                                </div>
                            </div>
                            <div className="embed-enabled-cat">
                                <div className="enable-sec">
                                    <div className="enable-title">
                                        Embeded Message:{" "}
                                        {formData?.welcomer.embed.enabled ? (
                                            // colorise the text depending on the status
                                            <span className="enable-status-enabled">Enabled</span>
                                        ) : (
                                            <span className="enable-status-disabled">Disabled</span>
                                        )}
                                    </div>
                                    <div className="enable-button">
                                        <label className="switch">
                                            <input
                                                name="enabled"
                                                className="switch"
                                                type="checkbox"
                                                checked={formData?.welcomer.embed.enabled}
                                                onChange={changeNestedSwitchState}
                                            />
                                            <span className="slider round"/>
                                        </label>
                                    </div>
                                </div>
                                <div
                                    className="embed-color-selector"
                                    style={
                                        formData?.welcomer.embed.enabled
                                            ? {display: "flex"}
                                            : {
                                                display: "none",
                                            }
                                    }
                                >
                                    <label htmlFor="color" className="colorLabel-embed">
                                        Embed Color:
                                    </label>
                                    <input
                                        type="color"
                                        name="color"
                                        id="color"
                                        placeholder="Color"
                                        disabled={!formData?.welcomer.embed.enabled}
                                        className="color-select"
                                        onChange={handleWelcNestedChange}
                                        value={formData?.welcomer.embed.color}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        className={
                            "guildCategory-submit" + " " + (formChanged ? "form-changed" : "")
                        }
                    >
                        <p>Changes asn&#39;t saved yet! Click to save.</p>
                        <input
                            className="guildCategory-submit-button"
                            value={formChanged ? "Save Changes" : "..."}
                            type="submit"
                            onClick={handleSubmit}
                        />
                    </div>
                </form>
            </div>
        </div>
    );
}
