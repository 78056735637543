import React, { useContext, useState, useEffect, useRef, useForwardRef } from "react";
import { UserContext } from "../utils/context/userContext";
import { useFetchGuildBot } from "./../utils/hooks/useFetchGuildBot";
import { useNavigate, useParams } from "react-router-dom";
import Dockers from "../components/GuildModules/Dockers/Dockers";
import Welcomer from "../components/GuildModules/Welcomer/Welcomer";
import { submitWelcomerForm } from "../utils/api";
import Goodbyeer from "../components/GuildModules/Goodbyeer/Goodbyeer";
import ComingSoon from "../components/Utils/ComingSoon/ComingSoon";

export default function DashEdit() {
  const { user, guilds, guildsLoading } = useContext(UserContext);
  const [guild, setGuild] = useState([]);
  const [formData, setFormData] = useState(null);
  const [channels, setChannels] = useState([]);
  const [formChanged, setFormChanged] = useState(false);
  const { botGuild, botGuildLoading } = useFetchGuildBot(
    window.location.pathname.split("/")[2]
  );

  const navigate = useNavigate()


  useEffect(() => {
    if (!guildsLoading && !botGuildLoading) {
      if (!guilds) {
        return navigate("/dashboard");
      }
      const guildId = window.location.pathname.split("/")[2];
      var guild = guilds.find((guild) => guild.id === guildId);

      if (!guild) {
        return navigate("/dashboard");
      }
      if (!user.loggedIn) {
        return navigate("/dashboard");
      }
      setGuild(guild);
      setFormData(botGuild);
      setChannels(botGuild?.channels);
    }
  }, [botGuildLoading, guilds, botGuild, guildsLoading, user.loggedIn]);

  

  // let fontSizes = [];
  // for (let i = 30; i < 61; i++) {
  //   fontSizes.push(i);
  // }

  

  var params = useParams();
  var module;

  switch (params.module) {
    case "welcomer":
      module = (
        <Welcomer
          guild={guild}
          formData={formData}
          setFormData={setFormData}
          formChanged={formChanged}
          setFormChanged={setFormChanged}
          channels={channels}
          submit={submitWelcomerForm}
          user={user}
        />
      );
      break;
    case "goodbyeer":
      module = (
        <Goodbyeer
          guild={guild}
          formData={formData}
          setFormData={setFormData}
          formChanged={formChanged}
          setFormChanged={setFormChanged}
          channels={channels}
          submit={submitWelcomerForm}
          user={user}
        />
      );
      break;
    default:
      module = (
        <ComingSoon/>
      )
  }

  return (
    <>
      <Dockers botGuild={botGuild} botGuildLoading={botGuildLoading}>{module}</Dockers>
    </>
  );
}
