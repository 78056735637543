import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import styles from "./Loader.module.css";

export default function Loader(prop) {
  const [styleProps, setStyleProps] = useState({
    opacity: 1,
  });
    const [unloaded, setUnloaded] = useState(false)

  useEffect(() => {
      if (prop.unload === true && unloaded === false) {
          setUnloaded(true)
          setTimeout(() => {
            
              setStyleProps({
                  opacity: 0,
                });
                setTimeout(() => {
                    setStyleProps({
                        opacity: 0,
                        display: "none",
                    });
                }, 900);
            },100)
    }
  }, [prop]);
  return (
    <div className={styles.container} style={styleProps}>
      <div className={styles.img_container}>
        <img
          className={styles.img}
          src="/logo512.png"
          alt="Welcomer logo loader"
        />
        <h1>Loading...</h1>
      </div>
    </div>
  );
}
