import { createContext } from "react";

export const UserContext = createContext({
  checkLoading: true,
  checkState: () => {},
  user: {
    loggedIn: false,
    username: undefined,
    id: undefined,
    avatar: undefined,
  },
});
